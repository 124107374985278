import React from 'react'

//import {Link } from 'react-router-dom';
//import About from '../pages/About';
import AboutModal from './AboutModal';

export default function Header() {
    return (
        <>
        <header>
            <h1 style={headerStyle}>
                <img src="img/camera-2412643_1920.png" alt={"Camera"} width="50" height="50" align="middle"></img>
                    <div style={{fontSize: '20px' , marginLeft: '4px' , marginRight: 'auto'}}> Secret Project Database </div>
                    <div style={{marginLeft: 'auto' , marginRight: '10px'}}><AboutModal /></div>
            </h1>
        </header>
        </>
    )
}
const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontSize: "30px",
    backgroundColor: "black",
    border: '3px solid #17a2b8',
    color: '#17a2b8',
    marginBottom: '0'
}