import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';

export default function AboutModal() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
        <Button variant="outline-info"  size="sm" onClick={handleShow}>
            About
        </Button> 
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '15px'}}>Surveillance Camera Database</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontSize: '15px'}}>Version 1.0.0</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" size="sm" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
    )
}
