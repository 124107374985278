import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
//import {Form, Button, Col} from 'react-bootstrap';
// onSelect={selectedKey => {alert(`selected ${selectedKey}`)}}

export default function NavBar(props) {
    return (
        <div style={navigationStyle}>
            <Nav activeKey="/" >
                <Nav.Item>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                            Select View
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="/">Camera Map</Dropdown.Item>
                            <Dropdown.Item href="/list">Camera List</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/contact" eventKey="contact">Contact</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/login" eventKey="login" disabled={props.isLoggedin} >Login</Nav.Link>
                </Nav.Item>
                    <div style={{ float:'right' , marginLeft: 'auto' , marginRight: '8px' , fontSize: '11px' , color: '#007bff'}}>Browser Location= {props.lat} , {props.long} -- Not logged in -- {props.currentDate}</div>
            </Nav>
        </div>
    )
}
const navigationStyle = {
    backgroundColor: "black",
    borderBottom: '3px solid #17a2b8',
    color: 'white',
}