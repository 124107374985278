import React, { Component } from 'react'
import { Map, InfoWindow, Marker, Circle, GoogleApiWrapper } from 'google-maps-react';
import {Form, FormControl,  InputGroup, Button, Modal, ButtonGroup, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewIncidentModal from './NewIncidentModal';
import axios from 'axios';

const myelement = <h1>I Love JSX!</h1>;

const mapStyles = {
    width: '100%',
    height: '100%',
    position: 'relative',
    feature: 'water',
};
const navigationStyle = {
  backgroundColor: "black",
  borderBottom: '3px solid #17a2b8',
  color: 'white',
};
const inputStyle = {
  backgroundColor: "black",
  color: 'white',
};
const formLabelStyle = {
  backgroundColor:'black',
  color:'#17a2b8',
  fontSize: '12px',
  border: '1px solid #17a2b8',
};
const formControlStyle = {
  backgroundColor:'black' ,
  color:'#007bff',
  fontSize: '12px',
  border: '1px solid #17a2b8',
};
const formCheckStyle = {
  backgroundColor:'black' ,
  color:'#007bff',
  fontSize: '12px',
};
const formButtonStyle = {
  backgroundColor:'#black',
  color:'#17a2b8',
  fontSize: '12px',
  width:'100px'
};
var chicago = {lat: 41.85, lng: -87.65};

export class GoogleMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingInfoWindow: false,
      activeMarker: null,
      selectedPlace: {name: ''},
      selectedLat: null,
      selectedLong: null,
      selectedURL: null,
      selectedurlYN: null,
      selectedSource: null,
      selectedObserved: false,
      incidentMarker: {key:'33',id:'7',longitude:this.props.long, latitude:this.props.lat},
      formLat: this.props.lat,
      formLong: this.props.long,
      formDescription: '',
      formSource:'',
      formObserved: false,
      circleCoords: {lat: Number(this.props.lat), lng: Number(this.props.long)},
      radius: Number(this.props.radius),
      camerasPublic:[],
      camerasPrivate:[],
      date:'',
      showNewCameraModal: false,
      showNewIncidentModal: false,
      newCameraDescription: 'Camera description - no personal information please',
      newCameraType: 'Camera type - image name for now'
    }
  }
  // handle the show and hide of the new Incident modal form
  showNewIncidentModalForm = () =>{
    this.setState({showNewIncidentModal:true });
  }
  hideNewIncidentModalForm = () =>{
    this.setState({showNewIncidentModal:false });
  }
  // handle the show and hide of the new camera modal form
  showNewCameraModalForm = (e) =>{
    this.setState({showNewCameraModal: true });
  }
  hideNewCameraModalForm = () =>{
    this.setState({showNewCameraModal:false });
  }
  handleNewCameraDescriptionChange = (event) =>{
    this.setState({newCameraDescription: event.target.value});
  }
  handleNewCameraTypeChange = (event) =>{
    this.setState({newCameraType: event.target.value});
  }
  handleNewCameraSubmit = (event) =>{
    console.log('New camera form submitted: ' + this.state.newCameraDescription + ' ' + this.state.newCameraType);
    event.preventDefault();
    axios.post(`https://`+this.props.apiurl+`/camera?` +
              `&long=`+this.state.formLong +
              `&lat=`+this.state.formLat +
              `&user=richardvalentineadams@gmail.com` +
              `&description=`+ this.state.newCameraDescription +
              `&marker_icon=`+ this.state.newCameraType )
    this.hideNewCameraModalForm();
  }
  getCamerasFromAPI(){
    axios.get(`https://camerasapi-3unib5smha-uc.a.run.app/list?long=`+this.state.formLong + `&lat=`+this.state.formLat + `&radius=` + this.state.radius)
    .then(res => {
      this.setState({ camerasPublic: res.data });
    })
  }
  onMapReady(){
    //this.getCamerasFromAPI();
  }
  componentDidMount() {
      this.getCamerasFromAPI();
      this.getDate();
  }
  getDate() {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    var hour = (date.getHours() + 100).toString().substring(1);
    var min = (date.getMinutes() + 100).toString().substring(1);
    var date1 =  year + "-" + month + "-" + day + " " + hour +":" + min;
    this.setState({
      //Setting the value of the date time
      date: date1
    });
  }
  onMarkerClick = (props, marker, e) => 
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      selectedLat: props.position.lat,
      selectedLong: props.position.lng,
      selectedID: props.id,
      selectedSource: props.source,
      showingInfoWindow: true,
      selectedurlYN: props.urlYN,
      selectedURL: props.imageURL,
      formLat: props.position.lat,
      formLong: props.position.lng,
      formDescription: props.name,
      formSource: props.source,
      formObserved: props.observed
    });
  displayURL = () =>{
    if(this.state.selectedurlYN === "Y"){
      return <p><Image src={this.state.selectedURL} alt={this.state.selectedPlace.name}/></p>
    }
  }
// handle on map clicks - also if clicked in the polygon, circle  
  onMapClicked = (mapProps, map, coord) => {
    const { latLng } = coord;
    const lat = latLng.lat().toFixed(6);
    const lng = latLng.lng().toFixed(6);
    this.setState({ 
      formLat: lat,                                                 
      formLong: lng ,
      formDescription: '',
      formSource:'',
      formObserved: false,
      incidentMarker: {key:'44',id:'7',longitude: lng, latitude: lat},
      circleCoords: latLng
    })
// close the info window if open
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
    this.getCamerasFromAPI()
    this.getDate()
  };
  displayPublicCameraMarkers = () =>{
    return this.state.camerasPublic.map((publicCams) => {
      return <Marker
                /* animation= {this.props.google.maps.Animation.DROP} */
                key={publicCams.owner_camera_id} 
                id={publicCams.owner_camera_id} 
                name={publicCams.camera_type + " - "+publicCams.camera_description} 
                position={{ lat: publicCams.camera_latitude , lng: publicCams.camera_longitude  }} 
                source={publicCams.source}
                observed={publicCams.observed}
/*                label={publicCams.source[0]} */
                icon={{url: "img/" + publicCams.marker_icon,
                        scaledSize:  new this.props.google.maps.Size(35,35)}}
                onClick={ this.onMarkerClick}
                urlYN={"Y"}
                imageURL={publicCams.camera_url} />
    })
  }
  displayIncidentMarkers = () =>{
    return <Marker 
                animation={this.props.google.maps.Animation.BOUNCE}
                key={this.state.incidentMarker.key} 
                id={this.state.incidentMarker.id}
                name={"NEW"} 
                position={{lat:this.state.incidentMarker.latitude , lng:this.state.incidentMarker.longitude}} 
                urlYN={"N"} />
  }
  render() {
    return (
      <>
        <InputGroup className="mb-3" style={navigationStyle}>
          <InputGroup.Prepend>
            <InputGroup.Text style={formLabelStyle}>Marker Latitude:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl style={formControlStyle} value={this.state.formLat} readOnly/>
          <InputGroup.Prepend>
            <InputGroup.Text style={formLabelStyle}>Marker Longitude:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl style={formControlStyle} value={this.state.formLong} readOnly/>
          <InputGroup.Prepend>
            <InputGroup.Text style={formLabelStyle}>Description:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl style={formControlStyle} value={this.state.formDescription} readOnly/>
          <InputGroup.Prepend>
            <InputGroup.Text style={formLabelStyle}>Source:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl style={formControlStyle} value={this.state.formSource} readOnly/>
          <Form.Check style={formControlStyle} checked={this.state.formObserved} readOnly/>
          <InputGroup.Append>
            <InputGroup.Text style={formLabelStyle}>Observed?</InputGroup.Text>
          </InputGroup.Append>
          <Button onClick={this.showNewCameraModalForm} style={formButtonStyle} size="sm" variant="outline-primary" type="simpleQuery">New camera</Button>
          <Button onClick={this.showNewIncidentModalForm} style={formButtonStyle} size="sm" variant="outline-primary" type="simpleQuery">New Incident</Button>
        </InputGroup>
        <Map
          google={this.props.google}
          zoom={13}
          style={mapStyles}
          initialCenter={{ lat: this.state.formLat, lng: this.state.formLong}}
          center={{lat: this.state.formLat,lng: this.state.formLong}}
          onClick={this.onMapClicked}
          onReady={this.onMapReady}
          zoomControl={true}
          fullscreenControl={false}
          mapTypeControl={true}>

          {this.displayPublicCameraMarkers()}
          {this.displayIncidentMarkers()}
       
          <Circle
            center={this.state.circleCoords}
            radius={this.state.radius - 6500}
            strokeColor="#f527ad"
            strokeOpacity={0.9}
            strokeWeight={2}
            fillColor="#f086cb"
            fillOpacity={0.1}
            onClick={this.onMapClicked} />
          <Circle
            radius={this.state.radius}
            center={this.state.circleCoords}
            onClick={this.onMapClicked}
            strokeColor='#eaba63'
            strokeOpacity={0.9}
            strokeWeight={2}
            fillColor='#eaba63'
            fillOpacity={0} />
          <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}> 
            <div>
              <p style={{backgroundColor: "white", color: 'blue', fontSize: '15px' , marginLeft: '0px'}}> {this.state.selectedPlace.name} </p>
              {this.displayURL()}                
              <p style={{backgroundColor: "white", color: 'blue', fontSize: '8px' , marginLeft: '0px'}}>{this.state.selectedLat} {this.state.selectedLong} Source: {this.state.selectedSource}</p>
            </div>
          </InfoWindow>
          <NewIncidentModal show={this.state.showNewIncidentModal} />   
          <Modal show={this.state.showNewCameraModal} onHide={this.hideNewCameraModalForm} >
            <Modal.Header closeButton style={formLabelStyle}>
              <Modal.Title>Create New Camera Record</Modal.Title>
            </Modal.Header>
            <Modal.Body style={formLabelStyle}>
              Create a new camera record based on the current maps marker. The new record will be saved under your user profile.
              <Form>
                <InputGroup className="mb-3" style={inputStyle}>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={formLabelStyle}>Latitude:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl style={formControlStyle} value={this.state.formLat} readOnly/>
                  <InputGroup.Prepend>
                   <InputGroup.Text style={formLabelStyle}>Longitude:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl style={formControlStyle} value={this.state.formLong} readOnly/>
                </InputGroup>
                <InputGroup className="mb-3" style={inputStyle}>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={formLabelStyle}>Description:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl style={formControlStyle} value={this.state.newCameraDescription} onChange={this.handleNewCameraDescriptionChange} />
                </InputGroup> 
                <InputGroup className="mb-3" style={inputStyle}>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={formLabelStyle}>Camera Type</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl style={formControlStyle} value={this.state.newCameraType} onChange={this.handleNewCameraTypeChange}/>
                </InputGroup>
                <ButtonGroup className="mb-3" style={inputStyle}>
                  <Form.Check inline style={formCheckStyle} type="checkbox" label="Make publically visible?"/>
                  <Form.Check inline style={formCheckStyle} type="checkbox" label="Observed?"/>
                </ButtonGroup>
                <ButtonGroup className="mb-3" style={inputStyle}>  
                  <Form.Check inline style={formCheckStyle} type="checkbox" label="Residential"/>
                  <Form.Check inline style={formCheckStyle} type="checkbox" label="Commercial"/>
                  <Form.Check inline style={formCheckStyle} type="checkbox" label="Government"/>
                </ButtonGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer style={formLabelStyle}>
              <Button variant="secondary" onClick={this.hideNewCameraModalForm}>
               Close
              </Button>
              <Button variant="primary" onClick={this.handleNewCameraSubmit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>     
        </Map>
      </>
    ); //end return
  } //end render
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCwB_oEomCVGLUJt9rFemMvzJc9zHd0jCU',
    libraries: ['places']
  })(GoogleMap);
