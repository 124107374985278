import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Login from './components/Login';
import CameraList from './components/CameraList';
import GoogleMap from './components/GoogleMap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default class App extends Component {
  state = {
    date:"",
    browserLatitude: "43.66090",
    browserLongitude: '-79.50417',
    isAuthenticated: false,
    apiurl: `camerasapi-3unib5smha-uc.a.run.app`
  }
  getDate() {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    var hour = (date.getHours() + 100).toString().substring(1);
    var min = (date.getMinutes() + 100).toString().substring(1);
    var date1 =  year + "-" + month + "-" + day + " " + hour +":" + min;
    this.setState({
      date: date1
    });
  }
  setBrowserGeoFunction = (locationGeos) => {
      console.log("In setBrowserGeoFunction "+locationGeos.coords.latitude.toFixed(6) 
                  + " "+ locationGeos.coords.longitude.toFixed(6) + " accuracy: " +
                  locationGeos.coords.accuracy)
      this.setState({browserLatitude: locationGeos.coords.latitude.toFixed(6)})
      this.setState({browserLongitude: locationGeos.coords.longitude.toFixed(6)})
  } 
  noLocation = (error) => {
    console.log("No Location returned from navigator.geolocation.getCurrentPosition. error.code= " + error.code + " - setting default location" )
    this.setState({browserLatitude: '43.00'})
    this.setState({browserLongitude: '-79.49'})
  }
  getCurrentLocation = () => {
    const options = {enableHighAccuracy: true};
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setBrowserGeoFunction, this.noLocation, options);
    } else {
      console.log("Geolocation is not supported by this browser")
      this.setState({browserLatitude: '43.001'});
      this.setState({browserLongitude: '-79.49685'});
    }
  }
  componentDidMount() {
    this.getCurrentLocation()
    this.getDate()
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Header />
          <Navbar lat={this.state.browserLatitude} long={this.state.browserLongitude} isLoggedin={this.state.isAuthenticated} currentDate={this.state.date}/>
          <Route exact path="/" render={props => (<GoogleMap {...props} lat={this.state.browserLatitude} long={this.state.browserLongitude} isLoggedin={this.state.isAuthenticated} apiurl={this.state.apiurl} radius="7500" />) }/>
          <Route path="/login" component={ Login } isLoggedin={this.state.isAuthenticated} />                
          <Route path="/list" render={props => ( 
            <React.Fragment>
              <CameraList lat={this.state.browserLatitude} long={this.state.browserLongitude} apiurl={this.state.apiurl} radius="7501" />
            </React.Fragment>
          )} />
        </Router>
      </div>
    );
  }
}