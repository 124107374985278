import React, { Component } from 'react'
import {Form, FormControl, InputGroup, Button, Modal, ButtonGroup} from 'react-bootstrap';

const inputStyle = {
    backgroundColor: "black",
    color: 'white',
  };
const formLabelStyle = {
    backgroundColor:'black',
    color:'#17a2b8',
    fontSize: '12px',
    border: '1px solid #17a2b8',
    };
const formControlStyle = {
    backgroundColor:'black' ,
    color:'#007bff',
    fontSize: '12px',
    border: '1px solid #17a2b8',
    };
const formCheckStyle = {
    backgroundColor:'black' ,
    color:'#007bff',
    fontSize: '12px',
    };

export default class NewIncidentModel extends Component {
    constructor(props){
        super(props)
        this.state = {
            showIncidentModal:this.props.show,
        };
    }
    handleClose = (event) =>{
        this.setState({showIncidentModal:false});
    }
    render() {
//        if (this.props.show) { this.setState({showIncidentModal:true} )}
        return (
            <Modal show={this.state.showIncidentModal} onHide={this.handleClose} >
                <Modal.Header closeButton style={formLabelStyle}>
                    <Modal.Title>Create New Incident Record</Modal.Title>
                </Modal.Header>
                <Modal.Body style={formLabelStyle}>
                    Create a new incident record based on the current maps marker. The new record will be saved under your user profile.
                    <Form>
                        <InputGroup className="mb-3" style={inputStyle}>
                            <InputGroup.Prepend>
                                <InputGroup.Text style={formLabelStyle}>Latitude:</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={formControlStyle} value={this.state.formLat}/>
                            <InputGroup.Prepend>
                                <InputGroup.Text style={formLabelStyle}>Longitude:</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={formControlStyle} value={this.state.formLong}/>
                        </InputGroup>
                        <InputGroup className="mb-3" style={inputStyle}>
                            <InputGroup.Prepend>
                                <InputGroup.Text style={formLabelStyle}>Description:</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={formControlStyle} />
                        </InputGroup> 
                        <InputGroup className="mb-3" style={inputStyle}>
                            <InputGroup.Prepend>
                                <InputGroup.Text style={formLabelStyle}>Camera Type</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={formControlStyle} type='text'/>
                        </InputGroup>
                        <ButtonGroup classname="mb-3" style={inputStyle}>
                            <Form.Check inline style={formCheckStyle} type="checkbox" label="Make publically visible?"/>
                            <Form.Check inline style={formCheckStyle} type="checkbox" label="Observed?"/>
                        </ButtonGroup>
                        <ButtonGroup classname="mb-3" style={inputStyle}>  
                            <Form.Check inline style={formCheckStyle} type="checkbox" label="Residential"/>
                            <Form.Check inline style={formCheckStyle} type="checkbox" label="Commercial"/>
                            <Form.Check inline style={formCheckStyle} type="checkbox" label="Government"/>
                        </ButtonGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> 
        )
    }
}