import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import '../App.css';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

export default class CameraList extends Component {
    state = {
        camerasAll:[]
    }
    componentDidMount() {
        axios.get(`https://`+this.props.apiurl+`/list?long=`+this.props.long +`&lat=` + this.props.lat +`&radius=`+ this.props.radius)
          .then(res => {
            this.setState({ camerasAll: res.data });
          })
    }
    getStyle = () => {
        return {
            backgroundColor: 'yellow',
            padding: '3px',
            border: '20px solid blue',
            textAlign: 'left',
        }
    }
    render() {
        return (
            <Table striped bordered hover size="sm" variant="dark">
                <thead style={{ fontSize: '12px'}}>
                    <tr>
                        <th></th>
                        <th>Camera ID</th>
                        <th>Description</th>
                        <th>Owner ID</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Camera Type</th>
                        <th>URL</th>
                        <th>Source</th>
                        <th>Observed</th>
                        <th>Private</th>
                        <th>Public</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.camerasAll.map((cameras) => (
                        <tr key={cameras.owner_camera_id} style={{fontSize: '12px'}}>
                            <td><Button variant="outline-light" style={{padding: '.52px'}}><img src={"img/"+ cameras.marker_icon} alt={"Camera"} width="25" height="20" align="middle"></img></Button></td>      
                            <td>{cameras.owner_camera_id }</td>
                            <td>{cameras.camera_description}</td>
                            <td>{cameras.owner}</td>
                            <td>{cameras.camera_latitude}</td>
                            <td>{cameras.camera_longitude}</td>
                            <td>{cameras.camera_type}</td>
                            <td><a href={cameras.camera_url} target="_blank" rel="noopener noreferrer"> {cameras.camera_url}</a></td>
                            <td>{cameras.source}</td>
                            <td>{String(cameras.observed)}</td>
                            <td style={{textDecoration: cameras.privately_visible ? 'none' : 'line-through' }}>{ String(cameras.privately_visible)}</td>
                            <td style={{textDecoration: cameras.publicly_visibile ? 'none' : 'line-through' }}>{ String(cameras.publicly_visibile)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}
// PropTypes
CameraList.propTypes = {
    cameralist: PropTypes.array.isRequired
}
